.class-cropper-modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.class-cropper-modal .modal-panel {
  width: 860px;
  height: 480px;
  background: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 50px;
}
.class-cropper-modal .modal-panel .button-row {
  height: 50px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.class-cropper-modal .modal-panel .button-row .submit-button {
  padding: 0 20px;
  height: 100%;
  color: #ffffff;
  font-size: 14px;
}
.class-cropper-modal .modal-panel .button-row .img-style {
  width: 20%;
  height: 2em;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
}
.class-cropper-modal .modal-panel .cropper-container-container {
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 100%;
}
.class-cropper-modal .modal-panel .cropper-container-container .cropper-container {
  flex: 0 0 600px;
  margin-right: 20px;
}
.class-cropper-modal .modal-panel .cropper-container-container .cropper-container .cropper {
  width: 100%;
  height: 100%;
}
.class-cropper-modal .modal-panel .cropper-container-container .preview-container {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.class-cropper-modal .modal-panel .cropper-container-container .preview-container .cropper-preview {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border: 1px solid #383838;
}

.c382-audio-list .thumb {
  width: 50px;
  height: 50px;
}
.c382-audio-list .description {
  word-wrap: break-word;
  word-break: break-all;
}
.audio-detail-modal {
  min-height: 500px;
}
.audio-detail-modal .item-row {
  display: flex;
  margin: 3px;
  padding: 1px;
}
.audio-detail-modal .item-key {
  font-weight: bold;
  display: inline-flex;
  justify-content: flex-start;
  text-transform: capitalize;
}
.audio-detail-modal .item-val {
  display: inline-block;
  margin-left: 5px;
}
.searchSkin {
  margin: 0px 20px;
}
.ant-col-sm-8 {
  display: block;
  box-sizing: border-box;
  width: 21%;
}
.ant-modal {
  width: 600px !important;
}

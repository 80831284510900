.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.background {
  background: url("/img/login_img.png") no-repeat center center;
  background-position-x: 20%;
  background-position-y: 50%;
  overflow: hidden;
}

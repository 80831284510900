.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.ant-layout-content {
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}
.ant-divider-horizontal {
  margin: 8px auto;
}
.header {
  background-color: #fff;
  padding: 0;
}
.header .currentAdmin {
  width: 90%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.header .currentAdmin button {
  margin-left: 5px;
}
.currentEmail {
  margin-left: 10px;
}

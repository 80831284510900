.c325-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(239, 241, 240, 0.5);
  z-index: 2;
  min-height: 200px;
  height: 100%;
  width: 100%;
}

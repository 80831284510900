.c382-producer-list .thumb {
  width: 50px;
  height: 50px;
}
.c382-producer-list .description {
  word-wrap: break-word;
  word-break: break-all;
}
.c382-producer-list .attachment {
  width: 200px;
}
.order_price_modal .title_style {
  font-weight: bold;
  font-size: 15px;
  clear: both;
}
.order_price_modal .totol_price {
  font-weight: bold;
  font-size: 15px;
  clear: both;
  position: absolute;
  right: 100px;
}
.order_price_modal .ant-input {
  width: 200px;
  margin: 0 8px 8px 0;
}
.order_price_modal .ant-col-sm-8 {
  display: block;
  box-sizing: border-box;
  width: 30%;
}
.order_price_modal .ant-form {
  overflow: hidden;
}
@media (min-width: 576px) {
  .order_price_modal .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 200px;
  }
}
.order_price_modal .ant-row {
  width: 50%;
  float: left;
}
.order_price_modal .ant-form-item {
  margin-bottom: 10px;
}
.order_price_modal .ant-input-number {
  width: 200px;
}
.order_price_modal .ant-form-text {
  text-align: left;
}

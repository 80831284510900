.c382-purchase-list .thumb {
  width: 50px;
  height: 50px;
}
.c382-purchase-list .description {
  word-wrap: break-word;
  word-break: break-all;
}
.c382-purchase-list .attachment-div {
  width: 300px;
  height: 200px;
  overflow: hidden;
}
.c382-purchase-list .attachment {
  width: 300px;
}

.musicItem__wave {
  overflow: hidden;
}
.musicItem__wave__img {
  margin-left: -1px;
}
.ant-row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: auto;
  zoom: 1;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.code-box-demo.ant-slider {
  margin-bottom: 16px;
}
.ant-col-12 {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.ant-row:before,
.ant-row:after {
  content: "";
  display: table;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #f5f5f5;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background-color: #91d5ff;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -4px;
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  border: solid 2px #91d5ff;
  background-color: #fff;
  -webkit-box-shadow: 0;
  box-shadow: 0;
  -webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider {
  font-size: 47px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  margin: 14px 6px 10px;
  padding: 4px 0;
  height: 12px;
  cursor: pointer;
  -ms-touch-action: none;
  touch-action: none;
}
.fixedPlayer__jacket img {
  width: 100%;
  height: 50px;
}
.wave-form-toggle-button {
  position: absolute;
  right: 0;
  top: -50px;
  background-color: gray;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .wave-form-toggle-button {
    position: absolute;
    right: 0;
    top: -30px;
    background-color: gray;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

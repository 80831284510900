.hidden {
  display: none;
  visibility: hidden;
}
.block {
  display: block;
}
.thumb-big {
  width: 100px;
  height: 100px;
}

.ys {
  width: 680px;
  margin: 0 auto;
}
.mar {
  display: inline-block;
  width: 60px;
}
.price_update_modal .title_style {
  font-weight: bold;
  font-size: 15px;
  clear: both;
}
.price_update_modal .ant-input {
  width: 200px;
  margin: 0 8px 8px 0;
}
.price_update_modal .ant-col-sm-8 {
  display: block;
  box-sizing: border-box;
  width: 30%;
}
.price_update_modal .ant-form {
  overflow: hidden;
}
@media (min-width: 576px) {
  .price_update_modal .ant-col-sm-16 {
    display: block;
    box-sizing: border-box;
    width: 200px;
  }
}
.price_update_modal .ant-row {
  width: 50%;
  float: left;
}
.price_update_modal .ant-form-item {
  margin-bottom: 10px;
}
.price_update_modal .ant-input-number {
  width: 200px;
}
